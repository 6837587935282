
  
  .timer-wrapper {
    display: flex;
    justify-content: center;
  }
  
  .timer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .text {
    color: #000;
    margin:0
  }
  
  .value {
    font-size: 30px;
  }
  
  .info {
    max-width: 360px;
    margin: 40px auto 0;
    text-align: center;
    font-size: 16px;
  }
  